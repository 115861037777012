<template>
  <span v-if="!isBigScreen" @click="openFilter" class="sidebar-arrow-right">
    <i class="pi pi-angle-right" style="font-size: 2rem"></i>
  </span>

  <div :class="isBigScreen ? 'main-sidebar' : 'main-sidebar-smallScreen'">
    <span v-if="!isBigScreen" class="sidebar-arrow-left" @click="closeFilter">
      <i class="pi pi-angle-left" style="font-size: 2rem"></i>
    </span>
    <p style="font-size: 30px; color: #848282; margin-bottom: 12px">
      {{ title }}
    </p>
    <slot name="before-filters"></slot>

    <section class="filter-header">
      <p>Filter</p>
      <hr />
    </section>

    <div
      v-if="filters.length === 0 || !hasManyFilters"
      class="sidebar-filter-content">
      <div
        v-if="currentFilter !== null"
        v-for="(filterOption, filterOptionIndex) in filtersOptions"
        :key="filterOption.field">
        <component
          v-if="currentFilter[filterOption.field] !== undefined"
          :key="filterOptionIndex"
          :is="getComponent(filterOption.valueType)"
          :filterOption="filterOption"
          :filterBy="currentFilter" />
      </div>
      <button class="sidebar-filter-btn" @click="applyChanges">Apply</button>
    </div>
    <div v-else class="filters-accordion-wrapper">
      <el-collapse
        v-model="currFilterIdToShow"
        accordion
        @change="openFilterAccordion">
        <el-collapse-item
          v-for="(filter, index) in filters"
          :key="filter"
          :title="`${filter.filterName ? filter.filterName : index + 1} (${
            filter.count
          })`"
          :name="index + 1">
          <!-- @click="openFilterAccordion2(filter.id)" -->
          <template #title>
            <div class="filter-accordion-header">
              {{ filter.filterName ? filter.filterName : index + 1 }} ({{
                filter.count
              }})
              <section class="filter-accordion-header-actions">
                <button
                  v-if="filter.filterIsShown"
                  class="icon-btn-filter"
                  @click.stop="handleFilterBouncedToggled(filter.id)">
                  <i v-if="currentPlayedFilterId !== filter.id" class="fa fa-play"></i>
                  <i v-else class="fa fa-pause"></i>
                </button>
                <button
                  class="icon-btn-filter"
                  @click.stop="
                    ;(filter.filterIsShown = !filter.filterIsShown),
                      handleFilterShownToggled()
                  ">
                  <i
                    v-if="filter.filterIsShown"
                    class="fas fa-check-square"></i>
                  <i style="font-size: 16px" v-else class="far fa-square"></i>
                </button>
                <button
                  class="icon-btn-filter"
                  @click.stop="removeFilter(filter.id)">
                  <i class="fas fa-times"></i>
                </button>
              </section>
            </div>
          </template>
          <div
            v-if="currentFilter !== null"
            v-for="(filterOption, filterOptionIndex) in filtersOptions"
            :key="filterOption.field">
            <component
              v-if="currentFilter[filterOption.field] !== null"
              :key="filterOptionIndex"
              :is="getComponent(filterOption.valueType)"
              :filterOption="filterOption"
              :filterBy="currentFilter" />
          </div>
          <button class="sidebar-filter-btn" @click="applyChanges">
            Apply
          </button>
        </el-collapse-item>
        <el-collapse-item
          v-if="currFilterIdToShow === 'NewFilter'"
          :title="'New Filter'"
          name="NewFilter">
          <div
            v-if="currentFilter !== null"
            v-for="(filterOption, filterOptionIndex) in filtersOptions"
            :key="filterOption.field">
            <component
              v-if="currentFilter[filterOption.field] !== undefined"
              :key="filterOptionIndex"
              :is="getComponent(filterOption.valueType)"
              :filterOption="filterOption"
              :filterBy="currentFilter" />
          </div>
          <button class="sidebar-filter-btn" @click="applyChanges">
            Apply
          </button>
        </el-collapse-item>
      </el-collapse>
      <button class="add-filter-btn" @click="addFilter">
        <span>Add Filter</span>
        <!-- <img :src="$buttonPlusSvg" alt="" /> -->
      </button>
    </div>

    <slot name="after-filters"></slot>
  </div>
</template>

<script lang="ts" setup>
  import './style/index.scss'

  import $ from 'jquery'

  import BaseStringInput from './filterInputs/BaseStringInput.vue'
  import BaseSelectInput from './filterInputs/BaseSelectInput.vue'
  import BaseMultiSelectInput from './filterInputs/BaseMultiSelectInput.vue'
  import BaseDateInput from './filterInputs/BaseDateInput.vue'
  import BaseBooleanInput from './filterInputs/BaseBooleanInput.vue'

  import {
    ref,
    onMounted,
    defineComponent,
    markRaw,
    defineEmits,
    watch
  } from 'vue'
  import { useGridManagement } from '../grid/grid-new-vue/utils/gridManagement.ts'
  import { buildApiRequestBody } from '../grid/grid-new-vue/utils/gridApi.ts'

  // Types
  import { GridView } from '../grid/grid-new-vue/types.ts'
  import { FilterOption, QueryItem } from './type/type'

  const props = defineProps<{
    title: string
    isBigScreen: boolean
    hasManyFilters: boolean
    filtersOptions: FilterOption[]
    hideFilters?: boolean
    filtersCount?: { filterID: number; projects: number[] }[]
  }>()

  const emit = defineEmits<{
    (event: 'closeFilter'): void
    (event: 'openFilter'): void
    (event: 'apply', payload: {} | {}[]): void
    (event: 'bounceFilter', filterId: number): void
  }>()

  defineComponent({
    components: {
      BaseStringInput: markRaw(BaseStringInput),
      BaseSelectInput: markRaw(BaseSelectInput),
      BaseMultiSelectInput: markRaw(BaseMultiSelectInput),
      BaseDateInput: markRaw(BaseDateInput),
      BaseBooleanInput: markRaw(BaseBooleanInput)
    }
  })

  const filters = ref<{ [key: string]: any }[]>([])
  const currFilterIdToShow = ref<string>('') // id of the filter to see
  const baseFilterObject = ref<null | {}>(null) // base filter object
  const currentFilter = ref<{ [key: string]: any }>({})
  const currentPlayedFilterId = ref()

  const emittedFilterBy = ref({
    query: {}
  })

  const inputsTypes = ref({
    string: BaseStringInput,
    selectOption: BaseSelectInput,
    selectMultipleOptions: BaseMultiSelectInput,
    dateInput: BaseDateInput,
    booleanInput: BaseBooleanInput
  })
  const { onSelectedGridViewChange } = useGridManagement('project_management/projects')

  onMounted(async () => {
    let filterObject = {}
    props.filtersOptions.forEach((filter) => {
      filter.defaultValue !== undefined
        ? (filterObject[filter.field] = filter.defaultValue)
        : (filterObject[filter.field] = '')
    })

    baseFilterObject.value = filterObject
    currentFilter.value = JSON.parse(JSON.stringify(filterObject))
  })

  const getComponent = (valueType: string) => {
    if (valueType === 'string') {
      return inputsTypes.value.string
    } else if (valueType.startsWith('selectOption')) {
      return inputsTypes.value.selectOption
    } else if (valueType.startsWith('selectMultipleOptions')) {
      return inputsTypes.value.selectMultipleOptions
    } else if (valueType.startsWith('date')) {
      return inputsTypes.value.dateInput
    } else if (valueType.startsWith('boolean')) {
      return inputsTypes.value.booleanInput
    }
  }

  const openFilterAccordion = (index: string) => {
    if (+index > 0) {
      const filter = filters.value[+index - 1]
      currentFilter.value = JSON.parse(JSON.stringify(filter))
    } else {
      currentFilter.value = JSON.parse(JSON.stringify(baseFilterObject.value))
    }
  }

   const handleFilterBouncedToggled = (filterId) => {
    if (currentPlayedFilterId.value === filterId) {
      currentPlayedFilterId.value = null
      emit('bounceFilter', 0)
    } else {
      currentPlayedFilterId.value = filterId
      emit('bounceFilter', filterId)
    }
   }

  const handleFilterShownToggled = () => emit('apply', filters.value)

  const removeFilter = (filterId) => {
    const index = filters.value.findIndex((filter) => filter.id === filterId)
    filters.value.splice(index, 1)

    if (filters.value.length === 0) {
      currentFilter.value = JSON.parse(JSON.stringify(baseFilterObject.value))
    }
    emit('apply', filters.value)
  }

  const addFilter = () => {
    currentFilter.value = JSON.parse(JSON.stringify(baseFilterObject.value))
    currFilterIdToShow.value = 'NewFilter'
  }

  const generateRandomID = () => {
    return Date.now()
  }

  const applyChanges = () => {
    let filterToApply = {
      query: {}
    }

    if (props.hasManyFilters) {
      if (currentFilter.value && currentFilter.value.id) {
        const index = filters.value.findIndex(
          (filter) => filter.id === currentFilter.value.id
        )
        filters.value.splice(index, 1, currentFilter.value)
      } else {
        currentFilter.value.id = generateRandomID()
        currentFilter.value['filterIsShown'] = true
        filters.value.push(currentFilter.value)
      }
      currFilterIdToShow.value = ''
      emit('apply', filters.value)
    } else {
      props.filtersOptions.forEach((filterOption) => {
        if (
          currentFilter.value &&
          currentFilter.value[filterOption.field] !== ''
        ) {
          if ( filterOption.valueType == 'selectOptionGridView' && filterOption?.options) {
            const option = filterOption.options.find(
              (option) =>
                option.id === currentFilter.value?.[filterOption.field]
            )
            if (option) {
              onSelectedGridViewChange(option as GridView)
              const gridParams = buildApiRequestBody( {request: {}} ,option as GridView, {})
              for (const [key, value] of Object.entries(gridParams.query.g)) {
                for (const [valueKey, valueValue] of Object.entries(value)) {
                  filterToApply.query[valueKey] = valueValue
                }
              }
            }
          } else {
            if (filterOption.query_item) {
              const queryItem: QueryItem = buildSidebarQuery(
                filterOption,
                currentFilter.value?.[filterOption.field]
              )
              if (queryItem?.field)
                filterToApply.query[queryItem.field] = [...queryItem.value]
            } else {
              filterToApply[filterOption.field] =
                currentFilter.value[filterOption.field]
            }
          }
        }
      })
      emittedFilterBy.value = filterToApply
      emit('apply', filterToApply)
    }

    if (!props.isBigScreen) {
      closeFilter()
    }
  }

  const getCurrentFilter = () => {
    const filter = Object.keys(emittedFilterBy.value.query).reduce(
      (acc, key) => {
        const newKey = key.replace(/_[^_]*$/, '')
        acc[newKey] = emittedFilterBy.value.query[key]
        return acc
      },
      {}
    )
    return filter
  }

  const buildSidebarQuery = (filterOption, filterValue) => {
    if (filterValue?.length === 0 || filterValue === '') return {}
    let query = {}

    let currentValueType = filterOption.valueType
    if (currentValueType.includes('selectOption'))
      currentValueType = 'selectOption'
    else if (currentValueType.includes('selectMultipleOptions'))
      currentValueType = 'selectMultipleOptions'

    switch (currentValueType) {
      case 'string':
        if (filterValue.length > 0) {
          // query.m = 'or'
          // query.g = filterValue.map((value) => {
          //   return { [`${filterOption.field}_cont`]: value }
          // })
        }
        break
      case 'boolean':
        query = {
          field: `${filterOption.field}_eq`,
          value: filterValue
        }
        break
      case 'selectOption':
        query = {
          field: `${filterOption.field}_eq`,
          value: filterValue
        }
        break
      case 'selectMultipleOptions':
        query = {
          field: `${filterOption.field}_in`,
          value: filterValue
        }
        break
      case 'date':
        query[`${filterOption.field}_gteq`] = filterValue[0]
        query[`${filterOption.field}_lteq`] = filterValue[1]
        break
    }
    return query
  }

  const closeFilter = () => {
    $('.main-sidebar-smallScreen').css('left', `-100%`)
    emit('closeFilter')
  }

  const openFilter = () => {
    $('.main-sidebar-smallScreen').css('left', `0%`)
    emit('openFilter')
  }

  watch(
    () => props.hideFilters,
    async (newVal) => {
      if (newVal) {
        filters.value = filters.value.map((filter) => {
          filter.filterIsShown = false
          return filter
        })
        emit('apply', filters.value)
      }
    },
    { deep: true }
  )

  watch(
    () => props.filtersCount,
    async (newVal) => {
      if (newVal) {
        newVal.forEach((filterCount) => {
          const filter = filters.value.find(
            (filter) => filter.id === filterCount.filterID
          )
          if (filter) {
            filter.count = filterCount.projects.length
          }
        })
      }
    },
    { deep: true }
  )

  defineExpose({
    applyChanges,
    getCurrentFilter
  })
</script>

<style lang="scss" scoped>
  .main-sidebar {
    padding: 20px;
    width: 18%;
    background-color: #f0f0f0;
    box-shadow: -4px 0 4px -4px inset;
    overflow-y: auto;
    .filter-header {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-bottom: 12px;
      p {
        font-size: 23px;
        color: #870d0d;
      }
      hr {
        width: 100%;
        margin: 0px auto;
        border-top: 1px solid #707070;
      }
    }

    .sidebar-filter-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 4px;

      .sidebar-filter-btn {
        display: flex;
        background-color: #5c6267;
        border-radius: 20px;
        width: 100%;
        padding: 6px 11px;
        align-items: center;
        justify-content: space-between;
        color: #fff;
        font-size: 16px;
        margin-top: 0.25rem;
        &:hover {
          background-color: #3c4043;
          cursor: pointer;
        }
      }
    }
  }

  .main-sidebar-smallScreen {
    position: fixed;
    z-index: 100;
    height: 100%;
    width: 100%;
    background-color: #f0f0f0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem 2rem;
    overflow-y: auto;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
      0 1px 3px 1px rgba(60, 64, 67, 0.15);
    transition: all 0.7s ease-in-out;
    left: -100%;
    .filter-header {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-bottom: 12px;
      width: 100%;
      p {
        font-size: 23px;
        color: #870d0d;
      }
      hr {
        width: 100%;
        margin: 0px auto;
        border-top: 1px solid #707070;
      }
    }

    .sidebar-filter-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 4px;

      .sidebar-filter-btn {
        display: flex;
        background-color: #5c6267;
        border-radius: 20px;
        width: 100%;
        padding: 6px 11px;
        align-items: center;
        justify-content: space-between;
        color: #fff;
        font-size: 16px;
        margin-top: 0.25rem;
        &:hover {
          background-color: #3c4043;
          cursor: pointer;
        }
      }
    }
  }

  strong {
    font-size: 13px;
    font-weight: 700;
    color: #707070;
    align-self: flex-start;
    display: flex;
  }

  .filters-accordion-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .el-collapse-item {
      .el-collapse-item__header {
        background-color: rgb(225, 225, 223) !important;
      }
    }
    .el-collapse-item__wrap {
      background: #f0f0f0 !important;
    }
    .el-collapse-item__content {
      padding-bottom: 0 !important;
    }
    .el-collapse-item:last-child {
      .el-collapse-item__header {
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }
    .el-collapse-item:first-child {
      .el-collapse-item__header {
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
      }
    }
    .filter-accordion-header {
      display: flex;
      gap: 8px;
      justify-content: space-between;
      width: 100%;
      padding-inline: 6px;
      .filter-accordion-header-actions {
        display: flex;
        .icon-btn-filter {
          display: flex;
          align-items: center;
          padding: 0 5px;
          &:hover {
            background-color: #bdbfbf;
          }
          i {
            font-size: 16px;
          }
        }
      }
    }
    .add-filter-btn {
      display: flex;
      background-color: #e1e1df;
      border-radius: 20px;
      width: 100%;
      padding: 6px 11px;
      align-items: center;
      justify-content: space-between;
      color: #5c6267;
      font-size: 16px;
      margin-bottom: 12px;
      &:hover {
        background-color: #b3b3b1;
        color: #333333;
        cursor: pointer;
      }
    }
  }

  .el-switch.mb-2 {
    text-align: center;
  }

  .el-input.el-input--small.el-input--prefix.el-input--suffix.el-date-editor.el-date-editor--date.el-tooltip__trigger.el-tooltip__trigger {
    width: 100% !important;
  }

  .el-input__wrapper {
    border-radius: 20px !important;
  }

  .el-select__wrapper {
    border-radius: 20px !important;
  }

  *::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #666;
    border-radius: 10px;
  }

  *::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  .filters-accordion-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .el-collapse-item {
      .el-collapse-item__header {
        background-color: rgb(225, 225, 223) !important;
      }
    }
    .el-collapse-item__wrap {
      background: #f0f0f0 !important;
    }
    .el-collapse-item__content {
      padding-bottom: 0 !important;
    }
    .el-collapse-item:last-child {
      .el-collapse-item__header {
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }
    .el-collapse-item:first-child {
      .el-collapse-item__header {
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
      }
    }
    .filter-accordion-header {
      display: flex;
      gap: 8px;
      justify-content: space-between;
      width: 100%;
      padding-inline: 6px;
      .filter-accordion-header-actions {
        display: flex;
        .icon-btn-filter {
          display: flex;
          align-items: center;
          padding: 0 5px;
          &:hover {
            background-color: #bdbfbf;
          }
          i {
            font-size: 16px;
          }
        }
      }
    }
    .add-filter-btn {
      display: flex;
      background-color: #e1e1df;
      border-radius: 20px;
      width: 100%;
      padding: 6px 11px;
      align-items: center;
      justify-content: space-between;
      color: #5c6267;
      font-size: 16px;
      margin-bottom: 12px;
      &:hover {
        background-color: #b3b3b1;
        color: #333333;
        cursor: pointer;
      }
    }
  }
</style>
