<template>
  <div>
    <strong>{{ filterOption.title }}</strong>
    <el-select v-model="selectedOption" placeholder="Select Period">
      <el-option
        v-for="option in ranges"
        :key="option.value"
        :value="option.title"
        @click="handleClick(option)">
        {{ option.title }}
      </el-option>
    </el-select>
  </div>

  <div v-if="selectedOption === 'Custom Date'">
    <div>
      <strong>Start Date:</strong>
      <el-date-picker
        @change="handleChangeStartDate"
        v-model="startDate"
        type="date"
        placeholder="Pick a day"
        size="small"
        :disabled-date="checkDateStartDate" />
      <strong></strong>
    </div>
    <div>
      <strong>End Date:</strong>
      <el-date-picker
        @change="handleChangeEndDate"
        v-model="endDate"
        type="date"
        placeholder="Pick a day"
        size="small"
        :disabled-date="checkDateEndDate" />
    </div>
    <strong class="selected-dates-wrapper">
      {{ startDate ? startDate : 'No Date Selected' }} -
      {{ endDate ? endDate : 'No Date Selected' }}
    </strong>
  </div>
</template>
<script lang="ts" setup>
  import moment from 'moment'

  import { ref, defineProps, onMounted, watch } from 'vue'
  import { FilterOption } from '../type/type'

  const props = defineProps<{
    filterOption: FilterOption
    filterBy: {}
  }>()

  const ranges = [
    {
      id: 'today',
      title: 'Today',
      value: [moment(), moment()]
    },
    {
      id: 'yesterday',
      title: 'Yesterday',
      value: [moment().subtract(1, 'days'), moment().subtract(1, 'days')]
    },
    {
      id: 'last_7_days',
      title: 'Last 7 Days',
      value: [moment().subtract(6, 'days'), moment()]
    },
    {
      id: 'last_30_days',
      title: 'Last 30 Days',
      value: [moment().subtract(29, 'days'), moment()]
    },
    {
      id: 'this_month',
      title: 'This Month',
      value: [moment().startOf('month'), moment().endOf('month')]
    },
    {
      id: 'last_month',
      title: 'Last Month',
      value: [
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month')
      ]
    },
    { title: 'Custom Date', value: 'Custom Date' }
  ]

  const selectedOption = ref<string>('Last 30 Days')
  const startDate = ref<string | null>(null)
  const endDate = ref<string | null>(null)
  const startDateForBE = ref<string | null>(null)
  const endDateForBE = ref<string | null>(null)

  onMounted(() => {
    if (props.filterBy[props.filterOption.field]) {
      let currentRange = ranges.find(
        (range) => range.id === props.filterBy[props.filterOption.field].id
      )
      if (currentRange && Array.isArray(currentRange.value)) {
        const dateForUser = currentRange.value.map((date) =>
          moment(date).format('YYYY-MM-DD')
        )
        props.filterBy[props.filterOption.field] = dateForUser
        selectedOption.value = currentRange.title
      }
    }
  })

  const handleClick = (range) => {
    let data = JSON.parse(JSON.stringify(range))
    if (data.value !== 'Custom Date') {
      const dataForUser = data.value.map((date) =>
        moment(date).format('YYYY-MM-DD')
      )
      props.filterBy[props.filterOption.field] = dataForUser
    }
  }

  const checkDateEndDate = (e) => {
    const receivedDate = new Date(startDate.value as string)
    const today = new Date()
    if (e > today || e < receivedDate) {
      return true
    } else {
      return false
    }
  }
  const checkDateStartDate = (e) => {
    let receivedDate: Date | null = null
    if (endDate.value) {
      receivedDate = new Date(endDate.value)
    }
    const today = new Date()
    if (e > today || (receivedDate && e > receivedDate)) {
      return true
    } else {
      return false
    }
  }
  const handleChangeStartDate = (e) => {
    if (!e) {
      startDate.value = null
      startDateForBE.value = null
      return
    }
    let a = moment(e)
    var dateForUser = a.format('YYYY-MM-DD')
    startDate.value = formatDate(dateForUser)
    startDateForBE.value = dateForUser
  }
  const handleChangeEndDate = (e) => {
    if (!e) {
      endDate.value = null
      endDateForBE.value = null
      return
    }
    let a = moment(e)
    var dateForUser = a.format('YYYY-MM-DD')
    endDate.value = formatDate(dateForUser)
    a = a.add(1, 'days')
    endDateForBE.value = a.format('YYYY-MM-DD')
  }

  function formatDate(date) {
    if (typeof date === 'string') {
      date = date.replace(' UTC', '+0000')
      date = new Date(date)
    }

    const options = { year: 'numeric', month: 'short', day: 'numeric' }
    const localDateString = date.toLocaleDateString('en-US', options)

    return localDateString
  }

  watch(
    () => startDateForBE.value,
    async (newVal) => {
      if (newVal && endDateForBE.value) {
        props.filterBy[props.filterOption.field] = [newVal, endDateForBE.value]
      }
    },
    { deep: true }
  )
  watch(
    () => endDateForBE.value,
    async (newVal) => {
      if (newVal && startDateForBE.value) {
        props.filterBy[props.filterOption.field] = [
          startDateForBE.value,
          newVal
        ]
      }
    }
  )
</script>