<template>
  <strong v-if="showElement">{{ filterOption.title }}</strong>
  <el-select v-if="showElement"
    :placeholder="filterOption.title"
    filterable
    :clearable="filterOption.defaultValue ? false : true"
    v-model="filterBy[filterOption.field]"
    @change="onSelectChange">
    <el-option
      v-for="option in filterOption.options"
      :key="option.id"
      :label="option.name"
      :value="option.id" />
  </el-select>
</template>

<script lang="ts" setup>
  import { defineProps, computed } from 'vue'
  import { FilterOption } from '../type/type'

  const props = defineProps<{
    filterOption: FilterOption
    filterBy: {}
  }>()

  const onSelectChange = (v) => {
    if (v === undefined) {
      props.filterBy[props.filterOption.field] = ''
    }
    if (props.filterOption.field === 'grid_view' && props.filterOption.options) {
      let grid_view_data = props.filterOption.options.find(
        (option) => option.id === props.filterBy[props.filterOption.field]
      )
    }
  }

  const showElement = computed(() => {
    if (!props.filterOption.itemDependency) return true
    return props.filterBy[props.filterOption.itemDependency.field] === props.filterOption.itemDependency.value
  })
</script>

<style>
  .el-switch.mb-2 {
    text-align: center;
  }

  .el-input.el-input--small.el-input--prefix.el-input--suffix.el-date-editor.el-date-editor--date.el-tooltip__trigger.el-tooltip__trigger {
    width: 100% !important;
  }

  .el-input__wrapper {
    border-radius: 20px !important;
  }

  .el-select__wrapper {
    border-radius: 20px !important;
  }
</style>
