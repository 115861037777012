<template>
  <strong>{{ filterOption.title }}</strong>
  <el-select
    multiple
    filterable
    :placeholder="filterOption.title"
    v-model="filterBy[filterOption.field]">
    <el-option
      v-for="option in getOptions"
      :key="option.id"
      :label="option.name"
      :value="option.id" />
  </el-select>
  <button
    v-if="filterOption.allowListInput"
    class="toggle-input-list-btn"
    @click.stop="showProjectsNamesInput = !showProjectsNamesInput">
    Enter a list of project names
  </button>
  <div v-if="showProjectsNamesInput" class="list-html-input-wrapper">
    <div class="html-input-wrapper">
      <textarea v-model="checkHtml" class="html-input"></textarea>
      <button @click.stop="processHTML">Process List</button>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ref, defineProps, computed } from 'vue'
  import { FilterOption } from '../type/type'

  const props = defineProps<{
    filterOption: FilterOption
    filterBy: {}
  }>()

  const showProjectsNamesInput = ref(false)
  const checkHtml = ref('')

  const getOptions = computed(() => {
    if (props.filterOption.filterOptions === undefined)
      return props.filterOption.options
    return props.filterOption.filterOptions(
      props.filterBy,
      props.filterOption.options
    )
  })

  const processHTML = () => {
    let arr = checkHtml.value
      .split('\n')
      .map((item) => decodeHtmlEntities(item))
    arr = getOptions.value
      .filter((obj) => arr.includes(obj.name))
      .map((item) => item.id)
    if (props.filterBy[props.filterOption.field]?.length > 0) {
      arr = arr.filter((id) => {
        if (!props.filterBy[props.filterOption.field].includes(id)) return id
      })
    }
    const newArray = [...props.filterBy[props.filterOption.field]]
    newArray.push(...arr)
    props.filterBy[props.filterOption.field] = newArray
    showProjectsNamesInput.value = false
    checkHtml.value = ''
  }

  const decodeHtmlEntities = (input) => {
    const entities = {
      '&amp;': '&'
    }
    for (const entity in entities) {
      if (entities.hasOwnProperty(entity)) {
        const replacement = entities[entity]
        input = input.replace(new RegExp(entity, 'g'), replacement)
      }
    }
    return input
  }
</script>

<style lang="scss">
  strong {
    font-size: 13px;
    font-weight: 700;
    color: #707070;
    align-self: flex-start;
    display: flex;
  }

  .el-switch.mb-2 {
    text-align: center;
  }

  .el-input.el-input--small.el-input--prefix.el-input--suffix.el-date-editor.el-date-editor--date.el-tooltip__trigger.el-tooltip__trigger {
    width: 100% !important;
  }

  .el-input__wrapper {
    border-radius: 20px !important;
  }

  .el-select__wrapper {
    border-radius: 20px !important;
  }

  .toggle-input-list-btn {
    background-color: #f4f4f5;
    border: 1px solid #b6b6b6;
    margin-bottom: 6px;
    font-size: 13px;
    cursor: pointer;
    border-radius: 20px;
    margin-top: 6px;
    width: 100%;
    &:hover {
      background-color: #e3e3e3;
    }
  }

  .list-html-input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;

    .html-input-wrapper {
      display: flex;
      flex-direction: column;
      gap: 6px;
      width: 100%;
      .html-input {
        border: 1px solid #ccc;
        border-radius: 20px;
        padding-inline: 11px;
        font-size: 14px;
        background-color: white;
        line-height: 1.6;
        outline: none;
        white-space: pre-wrap;
        overflow-wrap: break-word;
        word-wrap: break-word;

        &:focus {
          border-color: #3498db;
        }
      }
    }

    [contenteditable][data-placeholder]:empty:before {
      content: attr(data-placeholder);
      color: #bababa;
    }

    button {
      background-color: #f4f4f5;
      border: 1px solid #b6b6b6;
      margin-bottom: 6px;
      font-size: 13px;
      border-radius: 20px;
      cursor: pointer;
      &:hover {
        background-color: #e3e3e3;
      }
    }
  }
</style>
